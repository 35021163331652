import axios from '@/libs/axios'

export function reqGetPromos(payload) {
  return axios.get('api/admin/promos', { params: payload })
}
export function reqGetPromo(id) {
  return axios.get(`api/admin/promos/${id}`)
}
export function reqCreatePromo(payload) {
  return axios.post('api/admin/promos', payload)
}
export function reqUpdatePromo(id, payload) {
  return axios.post(`api/admin/promos/${id}?_method=PATCH`, payload)
}
export function reqDeletePromo(id) {
  return axios.delete(`api/admin/promos/${id}`)
}
